import React from 'react'
import Wrapper from '../components/Wrapper';
import {Col, Row} from "antd";

export default class IndexPage extends React.Component {
    render() {

        return (
            <Wrapper
                title={"404 - Page not found"}>
                <Row>
                    <Col xs={{span: 24}}>
                        <p>&nbsp;</p>
                        <h2>Oops... looks like you have found a broken link</h2>
                        <p>Unfortunately, the page you requested couldn't be found. This is likely down to our website change, however it could also be our developers fault.</p>
                        <p>Click the button below to go back to the homepage, or take a few moments to read through our latest blogs.</p>
                        <p><a href="/">Home</a></p>
                    </Col>
                </Row>
            </Wrapper>
        )
    }
}